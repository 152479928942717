body{
	height: auto;
}

#root{
}
/* 
#platformList .ant-card-head{
	background: red;
}

#projectList .ant-card-head{
	background: yellow;
}

#moduleList .ant-card-head{
	background: blue;
} */
#info{
	padding-bottom: 100px;
}

#info #platformBox{
	border: 3px solid blue;
	padding: 10px;
	margin-bottom: 10px;
	text-align: center;
}

#info #platformBoxInput{
	width: 600px;
}

#info #projectBox{
	text-align: center;
	border: 1px solid blue;
	margin-bottom: 10px;
}

#info #projectBoxName{
	text-align: center;
	background: none;
	padding: 10px;
	border-right: 1px solid blue;
	vertical-align: middle;
}

#info #projectBoxSupport{
	text-align: center;
	background: none;
	padding: 10px;
	border-left: 1px solid blue;
	border-right: 1px solid blue;
	vertical-align: middle;
}


#info #moduleBox{
	text-align: center;
	border: 1px solid blue;
	padding: 10px;
	height: 100%;
}

#info #moduleBoxNode{
	text-align: center;
	border-top: 1px solid blue;
}

#info #moduleBoxNode > div{
	height: 100%;
}

#info #moduleBoxNode span{
	vertical-align: middle;
}

#info #moduleBoxNode:first-child{
	border-top: none;
}